<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://ktlceramica.com/" target="_blank"
        >KTL Cerámica</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        href="http://keratile.es/aviso-legal/"
        target="_blank"
        >Aviso legal</b-link
      >
      <span class="ml-25"> </span>
      <b-link
        class="ml-25"
        href="http://keratile.es/politica-de-privacidad/"
        target="_blank"
        >Política de privacidad</b-link
      >
      <span class="ml-25" style="font-weight:bold;">Ver. 1.0 </span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
